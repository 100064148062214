import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/zhannum/git/the-dark-between/src/layouts/DefaultLayout.tsx";
const layoutProps = {};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><undefined parentName="p">{`
                `}<div {...{
          "className": "right center vertical"
        }}>{`
                  `}<img parentName="div" {...{
            "src": "/static/b4d1eb39094ca050d56d918bdd86962d/2bef9/Morne_a_young_man_wearing_a_turtle_neck_and_a_long_blue_coat_st_703294ce-f2e5-4ce6-909d-66de6073918a.png",
            "srcSet": ["/static/b4d1eb39094ca050d56d918bdd86962d/5ff7e/Morne_a_young_man_wearing_a_turtle_neck_and_a_long_blue_coat_st_703294ce-f2e5-4ce6-909d-66de6073918a.png 375w", "/static/b4d1eb39094ca050d56d918bdd86962d/1d69c/Morne_a_young_man_wearing_a_turtle_neck_and_a_long_blue_coat_st_703294ce-f2e5-4ce6-909d-66de6073918a.png 750w", "/static/b4d1eb39094ca050d56d918bdd86962d/2bef9/Morne_a_young_man_wearing_a_turtle_neck_and_a_long_blue_coat_st_703294ce-f2e5-4ce6-909d-66de6073918a.png 1024w"],
            "width": "400px",
            "className": "right center vertical"
          }}></img>{`
                  `}<div parentName="div" {...{
            "className": "caption"
          }}>{`Arlo Livingston`}</div>{`
                `}</div>{`
                `}</undefined></p>
    <p>{`Arlo Livingston is a student at `}<a parentName="p" {...{
        "href": "/The%20Basentia%20Omnia",
        "title": "The Basentia Omnia"
      }}>{`The Basentia`}</a>{` studying to be an `}<a parentName="p" {...{
        "href": "/Acronist#farseeker",
        "title": "Acronist"
      }}>{`Farseeker`}</a>{`. He seems to have connections to a man named `}<a parentName="p" {...{
        "href": "/Jax",
        "title": "Jax"
      }}>{`Jax`}</a>{` that has ties to the criminal underground, and has done jobs for the man. `}</p>
    <p>{`Arlo has also recently started working for `}<a parentName="p" {...{
        "href": "/Corrin%20Fane",
        "title": "Corrin Fane"
      }}>{`Corrin Fane`}</a>{` at the `}<a parentName="p" {...{
        "href": "/Saetorim%20Institute",
        "title": "Saetorim Institute"
      }}>{`Saetorim Institute`}</a>{`, and has become involved with the missing person's case of `}<a parentName="p" {...{
        "href": "/Enzo%20Quint",
        "title": "Enzo Quint"
      }}>{`Enzo Quint`}</a>{`, along with `}<a parentName="p" {...{
        "href": "/Sulazar%20Stillwater",
        "title": "Sulazar Stillwater"
      }}>{`Sulazar Stillwater`}</a>{`, `}<a parentName="p" {...{
        "href": "/Lucian%20Cullpepper",
        "title": "Lucian Cullpepper"
      }}>{`Lucian Cullpepper`}</a>{`, and `}<a parentName="p" {...{
        "href": "/Eileen%20Quint",
        "title": "Eileen Quint"
      }}>{`Eileen Quint`}</a>{`.`}</p>
    <h2>{`Background`}</h2>
    <p>{`It is unclear at this time how Arlo came to be a student at The Basentia, but it seems clear that he is paying his tuition through the jobs he receives from `}<a parentName="p" {...{
        "href": "/Jax",
        "title": "Jax"
      }}>{`Jax`}</a>{`, and now through his job at the Saetorim Institute.`}</p>
    <h2>{`Events of Enzo Quint's Dissapearance`}</h2>
    <p>{`Arlo, through the `}<a parentName="p" {...{
        "href": "/Saetorim%20Institute",
        "title": "Saetorim Institute"
      }}>{`Saetorim Institute`}</a>{`, has been involved in the case of Enzo Quint's dissapearance and suspected kidnapping. `}</p>
    <p>{`Using his connection with `}<a parentName="p" {...{
        "href": "/Jax",
        "title": "Jax"
      }}>{`Jax`}</a>{`, Arlo was able to obtain a copy of `}<a parentName="p" {...{
        "href": "/The%20Second%20Tinwheel",
        "title": "The Second Tinwheel"
      }}>{`The Second Tinwheel`}</a>{` that `}<a parentName="p" {...{
        "href": "/Enzo%20Quint",
        "title": "Enzo Quint"
      }}>{`Enzo Quint`}</a>{` created, revealing further evidence into his dissapearance. In return, Arlo infiltrated a `}<a parentName="p" {...{
        "href": "/Sonxai",
        "title": "Sonxai"
      }}>{`Sonxai`}</a>{` warehouse containing `}<a parentName="p" {...{
        "href": "/Lotus",
        "title": "Lotus"
      }}>{`Lotus`}</a>{` to attempt to figure out how the drug is made. He encountered a `}<a parentName="p" {...{
        "href": "/Deep%20Dwellers",
        "title": "Deep Dwellers"
      }}>{`Msanti`}</a>{` there guarding the warehouse, and while unable to obtain a recipe for the drug, did escape with a bathymetric map of the bay outside of `}<a parentName="p" {...{
        "href": "/Meripol",
        "title": "Meripol"
      }}>{`Meripol`}</a>{`, with a mysterious location marked on it.`}</p>
    <p>{`Arlo was also a part of the investigation into the `}<a parentName="p" {...{
        "href": "/Lauten%20Family",
        "title": "Lauten Family"
      }}>{`Lauten Family`}</a>{` and their relation to Enzo's dissapearance. He, along with `}<a parentName="p" {...{
        "href": "/Eileen%20Quint",
        "title": "Eileen Quint"
      }}>{`Eileen Quint`}</a>{`, and `}<a parentName="p" {...{
        "href": "/Sulazar%20Stillwater",
        "title": "Sulazar Stillwater"
      }}>{`Sulazar Stillwater`}</a>{`, acted as hired staff for one of the galas that the Lauten's regularly host. During the party, Arlo discovered a `}<a parentName="p" {...{
        "href": "/Shifter",
        "title": "Shifter"
      }}>{`Shifter`}</a>{` named `}<a parentName="p" {...{
        "href": "/Elijah",
        "title": "Elijah"
      }}>{`Elijah`}</a>{` posing as `}<a parentName="p" {...{
        "href": "/Rikkart%20Lauten",
        "title": "Rikkart Lauten"
      }}>{`Rikkart Lauten`}</a>{`, and learned that Elijah had been hired by the family to pose as Rikkart for the past fifteen years, after Rikkart's `}<a parentName="p" {...{
        "href": "/Thread",
        "title": "Thread"
      }}>{`Thread`}</a>{` was severed in `}<a parentName="p" {...{
        "href": "/Mehnateruna",
        "title": "Mehnateruna"
      }}>{`Mehnateruna`}</a>{`.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      